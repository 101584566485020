<template>
<section class="admin-page">
    <div class="wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="dashboard-agencylist">
                        <div class="agencylist-header">
                            <h3>Agency List</h3>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addagency">
                                <i class="las la-plus"></i> Add Agency
                            </button>
                        </div>
                        <table id="myTable" class="table table-bordered mt-5">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Agency Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>City</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <div class="agname">
                                            <img src="../assets/images/upload/texi-service.jpg" alt="logo">
                                            <p>Texi Service</p>
                                        </div>
                                    </td>
                                    <td>info@texiservice.com</td>
                                    <td>+95321654987</td>
                                    <td>New York</td>
                                    <td>
                                        <router-link class="edit" to="/agency-list/agency-view"><i class="las la-eye"></i></router-link>
                                        <router-link class="delete" to="/agency-list/agency-view"><i class="las la-trash-alt"></i></router-link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <div class="agname">
                                            <img src="../assets/images/upload/company.png" alt="logo">
                                            <p>Company Service</p>
                                        </div>
                                    </td>
                                    <td>company@gmail.com</td>
                                    <td>+95456789321</td>
                                    <td>Los Angeles</td>
                                    <td>
                                        <router-link class="edit" to="/agency-list/agency-view"><i class="las la-eye"></i></router-link>
                                        <router-link class="delete" to="/agency-list/agency-view"><i class="las la-trash-alt"></i></router-link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <div class="agname">
                                            <img src="../assets/images/upload/taxi.jpg" alt="logo">
                                            <p>Taxi Service</p>
                                        </div>
                                    </td>
                                    <td>taxi@gmail.com</td>
                                    <td>+95987654213</td>
                                    <td>Anchorage</td>
                                    <td>
                                        <router-link class="edit" to="/agency-list/agency-view"><i class="las la-eye"></i></router-link>
                                        <router-link class="delete" to="/agency-list/agency-view"><i class="las la-trash-alt"></i></router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="addagency" tabindex="-1" aria-labelledby="addagencyLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addagencyLabel">Add New Agency</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form action="" class="add-form">
                        <label for="agencyname">Agency name</label>
                        <input type="text" id="agencyname" placeholder="Agency Name" class="form-control">

                        <label for="agencyphone">Phone Number</label>
                        <input type="text" id="agencyphone" placeholder="Phone Number" class="form-control">

                        <label for="agencyemail">Email</label>
                        <input type="text" id="agencyemail" placeholder="Email" class="form-control">

                        <label for="agencyaddress">Address</label>
                        <textarea id="agencyaddress" placeholder="Address" class="form-control" rows="4"></textarea>

                        <label for="agencyaddress">Agency Logo</label><br>
                        <input type="file" accept="image/*" @change="onChange" />
                        <div id="preview">
                            <img v-if="item.imageUrl" :src="item.imageUrl" src="../assets/images/upload/texi-service.jpg" />
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary"><i class="las la-plus"></i> Add</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
// import axios from 'axios';
// import $ from "jquery";

export default {
    name: 'agencylist',
    data() {
        return {
            item: {
                image: null,
                imageUrl: null
            }
        }
    },
    methods: {
        onChange(e) {
            const file = e.target.files[0]
            this.image = file
            this.item.imageUrl = URL.createObjectURL(file)
        }
    }
    // mounted() {
    //     this.getUsers();
    // },
    // methods: {
    //     getUsers() {
    //         axios.get('https://jsonplaceholder.typicode.com/posts')
    //             .then(response => {
    //                 $('#myTable').DataTable({
    //                     data: response.data,
    //                     columns: [{
    //                             data: ['id']
    //                         },
    //                         {
    //                             data: ['userId']
    //                         },
    //                         {
    //                             data: ['title']
    //                         },
    //                         {
    //                             data: ['body']
    //                         },
    //                     ],
    //                 });
    //             });
    //     },
    // },
};
</script>
