<template>
    <section class="admin-page">
        <div class="wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="dashboard-agencylist">
                            <div class="agencylist-header">
                                <h3>Driver List</h3>
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addagency">
                                    <i class="las la-plus"></i> Add Driver
                                </button>
                            </div>
                            <table id="myTable" class="table table-bordered mt-5">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Driver Name</th>
                                        <!-- <th>Agency Name</th> -->
                                        <th>Number of Driver</th>
                                        <th>Phone Number</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addagency" tabindex="-1" aria-labelledby="addagencyLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addagencyLabel">Add New Driver</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form action="" class="add-form">
                            <label for="drivername">Driver name</label>
                            <input type="text" id="drivername" placeholder="Driver Name" class="form-control">

                            <label for="driverphone">Phone Number</label>
                            <input type="text" id="driverphone" placeholder="Phone Number" class="form-control">

                            <label for="driveremail">Email</label>
                            <input type="text" id="driveremail" placeholder="Email" class="form-control">

                            <label for="driveraddress">Address</label>
                            <textarea id="driveraddress" placeholder="Address" class="form-control" rows="4"></textarea>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary"><i class="las la-plus"></i> Add</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </template>
    
    <script>
    import axios from 'axios';
    import $ from "jquery";
    
    export default {
        name: 'driverlist',
        mounted() {
            this.getUsers();
        },
        methods: {
            getUsers() {
                axios.get('https://jsonplaceholder.typicode.com/posts')
                    .then(response => {
                        $('#myTable').DataTable({
                            data: response.data,
                            columns: [{
                                    data: ['id']
                                },
                                {
                                    data: ['userId']
                                },
                                {
                                    data: ['title']
                                },
                                {
                                    data: ['body']
                                },
                            ],
                        });
                    });
            },
        },
    };
    </script>
    