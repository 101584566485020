<template>
<section class="admin-page">
    <div class="wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <router-link to="/agency-list">
                        <div class="count-widgets">
                            <div class="wicon">
                                <i class="las la-truck-monster"></i>
                            </div>
                            <div class="wtitle">
                                <h3>320</h3>
                                <p>Rider Company(Agency)</p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3">
                    <router-link to="/drivers-list">
                        <div class="count-widgets">
                            <div class="wicon">
                                <i class="las la-user-nurse"></i>
                            </div>
                            <div class="wtitle">
                                <h3>320</h3>
                                <p>Total Drivers</p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3">
                    <router-link to="/trips-list">
                        <div class="count-widgets">
                            <div class="wicon">
                                <i class="las la-road"></i>
                            </div>
                            <div class="wtitle">
                                <h3>320</h3>
                                <p>Total Trips</p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3">
                    <router-link to="/sos-alert-list">
                        <div class="count-widgets">
                            <div class="wicon">
                                <i class="las la-bell"></i>
                            </div>
                            <div class="wtitle">
                                <h3>320</h3>
                                <p>Today SOS Alert</p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12">
                    <!-- <button @click="toggleBodyClass('addClass', 'noScroll')">Add Class</button> -->
                </div>
                <!-- <div class="col-md-12">
                    <div class="dashboard-agencylist">
                        <div class="agencylist-header">
                            <h3>Agency List</h3>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addagency">
                                <i class="las la-plus"></i> Add Agency
                            </button>
                        </div>
                        <table id="myTable" class="table table-bordered mt-5">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Agency Name</th>
                                    <th>Number of Driver</th>
                                    <th>Phone Number</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="modal fade" id="addagency" tabindex="-1" aria-labelledby="addagencyLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addagencyLabel">Add New Agency</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary"><i class="las la-plus"></i> Add</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
import $ from "jquery";

export default {
    name: 'Dashboards',
    mounted() {
        this.getUsers();
    },
    // mounted() {
    //     this.toggleBodyClass('addClass', 'yourClassName');
    // },
    // destroyed() {
    //     this.toggleBodyClass('removeClass', 'yourClassName');
    // },
    methods: {
        

        getUsers() {
            axios.get('https://jsonplaceholder.typicode.com/posts')
                .then(response => {
                    $('#myTable').DataTable({
                        data: response.data,
                        columns: [{
                                data: ['id']
                            },
                            {
                                data: ['userId']
                            },
                            {
                                data: ['title']
                            },
                            {
                                data: ['body']
                            },
                        ],
                    });
                });
        },
    },
};
</script>

<style>
    </style>
