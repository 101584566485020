<template>
<section class="admin-page">
    <div class="wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">User Management</div>
            </div>
        </div>
    </div>

</section>
</template>

<script>
export default {
    name: 'Usermanagement',
}
</script>
