<template lang="html">
<!-- <PageSidebar />
<PageHeaders />
<PageWrapper /> 
<LoginPage />
<Dashboards /> -->
<div>
    <router-view></router-view>
    <teleport v-if="!$route.meta.hideSidebar" to="#sidebar">
        <PageSidebar />
    </teleport>
    <teleport v-if="!$route.meta.hideNavber" to="#header">
        <PageHeaders />
    </teleport>
</div>
</template>

<script>
import PageSidebar from './components/PageSidebar.vue'
import PageHeaders from './components/PageHeaders.vue'
import PageWrapper from './components/PageWrapper.vue'
import LoginPage from './components/LoginPage.vue'
import Dashboards from './components/Dashboards.vue'
import Agencylist from './components/agency-list.vue'
import Driverlist from './components/drivers-list.vue'
import Agencydetails from './components/agency-details.vue'

export default {
    name: 'App',
    components: {
        PageSidebar,
        PageHeaders,
        PageWrapper,
        LoginPage,
        Dashboards,
        Agencylist,
        Agencydetails,
        Driverlist,
    },
    data() {
        // return {
        //     tab: 'Dashboards'
        // }
        
    }
}
</script>

<style>
/* 
vue create rider-security
cd rider-security
code .
npm run serve */
</style>
