<template>
    <!-- <sidebar-menu :menu="menu" /> -->
<div class="sidebar">
    <div class="sidebar-menu">
        <ul>
            <li>
                <router-link to="/dashboard" class-active="active"><i class="las la-home"></i> Dashboards</router-link>
            </li>
            <li>
                <router-link to="/drivers-list"><i class="las la-user"></i> User management</router-link>
            </li>
            <li>
                <router-link to="/reports"><i class="las la-chart-pie"></i> Reports</router-link>
            </li>
            <li>
              <router-link to="/cms-Pages"><i class="las la-pager"></i> CMS Pages</router-link>
              <ul>
                <router-link to="/cms-Pages"> FAQ</router-link>
                <router-link to="/cms-Pages"> Term and Conditions</router-link>
              </ul>
                
            </li>

        </ul> 
    </div>
</div>
</template>

<script>
  export default {
    // data() {
    //   return {
    //     menu: [
    //       {
    //         header: 'Main Navigation',
    //         hiddenOnCollapse: true
    //       },
    //       {
    //         href: '/dashboard',
    //         title: 'Dashboard',
    //         icon: 'las la-home'
    //       },
    //       {
    //         href: '/user-management',
    //         title: 'User management',
    //         icon: 'las la-user',
    //         // child: [
    //         //   {
    //         //     href: '/charts/sublink',
    //         //     title: 'Sub Link'
    //         //   }
    //         // ]
    //       },
    //       {
    //         href: '/reports',
    //         title: 'Reports',
    //         icon: 'las la-chart-pie'
    //       },
    //       {
    //         href: '',
    //         title: 'CMS Pages',
    //         icon: 'las la-pager',
    //         child: [
    //           {
    //             href: '/page/faq',
    //             title: 'FAQ'
    //           },
    //           {
    //             href: '/page/teams',
    //             title: 'Term and Conditions'
    //           }
    //         ]
    //       },
    //     ]
    //   }
    // }
  }
</script>
