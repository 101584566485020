// main.js

import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "jszip";
import "pdfmake";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
// import VueSidebarMenu from 'vue-sidebar-menu';
// import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

import "./assets/css/style.css";

import Dashboard from "./components/Dashboards";
import LoginPage from "./components/LoginPage.vue";
import Usermanagement from "./components/Usermanagement.vue";
import PageNotFound from "./components/PagenotFound.vue";
import Agencylist from "./components/agency-list.vue";
import Agencydetails from "./components/agency-details.vue";
import Driverlist from "./components/drivers-list.vue";


const app = createApp(App);
// app.use(VueSidebarMenu);
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: LoginPage,
      meta: {
        title: "Guardian Link - Login",
        hideNavber: true,
        hideSidebar: true,
      },
    },
    {
      path: "/dashboard",
      component: Dashboard,
      meta: {
        title: "Guardian Link - Dashboard",
        requiresAuth: true,
      },
    },
    {
      path: "/agency-list",
      component: Agencylist,
      meta: {
        title: "Guardian Link - Agency List",
        requiresAuth: true,
      },
    },
    {
      path: "/drivers-list",
      component: Driverlist,
      meta: {
        title: "Guardian Link - Driver List",
        requiresAuth: true,
      },
    },
    {
      path: "/user-management",
      component: Usermanagement,
      meta: {
        title: "Guardian Link - User Management",
      },
    },
    {
      path: "/agency-list/agency-view",
      component: Agencydetails,
      meta: {
        title: "XYZ Agency - Guardian Link",
      },
    },
    {
      path: "/:pathMatch(.*)*",
      component: PageNotFound,
      meta: {
        hideNavber: true,
        hideSidebar: true,
        title: "404 Page Not Found - Guardian Link",
      },
    },
    // {
    //   path: "/reports",
    //   component: Reports,
    // },
    // {
    //   path: "/cms-pages",
    //   component: CMDPages,
    // },
  ],
});
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});
app.use(router);
app.mount("#app");
