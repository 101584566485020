<template>
<div class="header" :class="isOpen ? 'top-line' : ''">
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid">

            <router-link class="navbar-brand" to="/dashboard"><img src="../assets/images/logo.png" alt="logo"></router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <button class="sidebar-toggle" @click="toggleBodyClass('addClass', 'togglesidebar')"><i class="las la-bars"></i></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item dropdown option-menu">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="las la-user-cog"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#"><i class="las la-user-tie"></i> My Account </a></li>
                            <li><router-link to="/" class="dropdown-item"><i class="las la-sign-out-alt"></i> Log Out</router-link></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
import $ from "jquery";
export default {
    name: 'PageHeaders',
    methods: {
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === 'addClass') {
                el.classList.toggle(className);
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
</style>
