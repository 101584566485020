<template>
<section class="loginpage">
    <div class="login-box">
        <h1>Login</h1>
        <form action="">
            <div class="form-gorup">
                <label for="email">Email</label>
                <i class="las la-user"></i>
                <input type="text" name="email" id="email" class="form-control" placeholder="Email" v-model="lform.email" />
                <span class="focus-input"></span>
            </div>

            <div class="form-gorup">
                <label for="password">Password</label>
                <i class="las la-lock"></i>
                <input type="password" name="password" id="password" class="form-control" placeholder="Password" v-model="lform.password" />
                <span class="focus-input"></span>
            </div>
            <p class="error" v-for="item in error" v-bind:key="item">
                {{ item }} Required.
            </p>
            <button v-on:click="login" class="btn login-btn" type="button" @click="goToAboutPage">Log In</button>
            <!-- <router-view></router-view> -->
        </form>
    </div>
</section>
</template>

<script>
export default {
        name: 'LoginPage',
        data() {
            return {
                lform: {
                    email: '',
                    password: '',
                },
                error: [],
            }
        },
        methods: {
            login() {
                this.error = [];
                for (const item in this.lform) {
                    if (this.lform[item] === "" || this.lform[item].length === 0) {
                        this.error.push(item);
                    }
                }
                if (this.error.length === 0) {
                    if(this.lform['email'] === "admin@gmail.com" ){
                        if(this.lform['password'] === '123456'){
                            window.localStorage.setItem('authenticated', true);
                            this.$router.push('/dashboard');
                        }
                    }
                    //alert("Login Successful.");
                    
                    // window.location.href="/dashboard";
                    
                }
                console.warn(this.error)
            }
        },
}
</script>
