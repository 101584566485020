<template>
<section class="admin-page">
    <div class="wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="agency-det">
                        <div class="agedet-head">
                            <h1>Texi Service</h1>
                            <router-link to="" data-bs-toggle="modal" data-bs-target="#addagency"><i class="las la-edit"></i> Edit</router-link>
                        </div>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="ag-profile">
                                    <img src="../assets/images/upload/texi-service.jpg" alt="logo">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="ag-info">
                                    <label>Agency Name</label>
                                    <p>Texi Service</p>
                                    <label>Email</label>
                                    <p>info@texiservice.com</p>
                                    <label>Phone</label>
                                    <p>+1(575) 885-8870</p>
                                    <label>City</label>
                                    <p>New York</p>
                                    <label>Address</label>
                                    <p>217 N Canal St, Carlsbad, New York, 88220, United States</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="addagency" tabindex="-1" aria-labelledby="addagencyLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addagencyLabel">Edit Agency</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form action="" class="add-form">
                        <label for="agencyname">Agency name</label>
                        <input type="text" id="agencyname" placeholder="Agency Name" class="form-control" value="Texi Service">

                        <label for="agencyphone">Phone Number</label>
                        <input type="text" id="agencyphone" placeholder="Phone Number" class="form-control" value="+1(575) 885-8870">

                        <label for="agencyemail">Email</label>
                        <input type="text" id="agencyemail" placeholder="Email" class="form-control" value="info@texiservice.com">

                        <label for="agencyaddress">Address</label>
                        <textarea id="agencyaddress" placeholder="Address" class="form-control" rows="4">217 N Canal St, Carlsbad, New York, 88220, United States</textarea>

                        <input type="file" accept="image/*" @change="onChange" />
                        <div id="preview">
                            <img v-if="item.imageUrl" :src="item.imageUrl" src="../assets/images/upload/texi-service.jpg" />
                        </div>

                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary"><i class="las la-plus"></i> Add</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
  name: 'Agencydetails',
  data() {
    return {
      item:{
          image : null,
          imageUrl: null
      }
    }
  },
  methods: {
    onChange(e) {
      const file = e.target.files[0]
      this.image = file
      this.item.imageUrl = URL.createObjectURL(file)
    }
  }
};
</script>

<style>
        </style>
